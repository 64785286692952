.teaser-3col {
  margin: 80px 0 50px 0;
}

.teaser-3col__slider {
  margin: 20px 0;
}

.teaser-3col__item {
  margin: 0 15px;
}

.teaser-3col__item-img {
  display: block;
  padding-bottom: 78%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 15px;
}

.teaser-3col__item-title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: $primary;
  line-height: 1.2;
  margin-bottom: 10px;
}

.teaser-3col__item-text {
  display: block;
  font-size: 16px;
  color: #555555;
  line-height: 1.2;
}

.teaser-3col__globallink {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: $primary;
  line-height: 1.2;
  transition: all 0.3s;

  &:before {
    content: "";
    height: 14px;
    width: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
    background-size: contain;
    background-image: url("../img/right-arrow-blue.svg");
    background-repeat: no-repeat;
    margin-right: 7px;
  }
}

.teaser-3col .slick-next, .teaser-3col .slick-prev {
  height: 60px;
  width: 20px;
  transform: none;
  top: 31%;

  svg path {
    transition: fill 0.3s;
  }

  &:hover svg path {
    fill: $primary;
  }

  &:before {
    content: none;
  }
}

.teaser-3col .slick-dots li button {
  position: relative;
  top: 50%;
  margin: -6px auto 0 auto;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid $primary;
  border-radius: 100%;
}

.teaser-3col .slick-dots li button:before {
  content: none;
}

.teaser-3col .slick-dots li.slick-active button {
  background-color: $primary;
  border: 1px solid $primary;
}

.teaser-3col .slick-dotted.slick-slider {
  margin-bottom: 60px;
}

.teaser-3col .slick-dots {
  bottom: -40px;
}

@include media-breakpoint-up(md) {

  .teaser-3col__item-title {
    font-size: 20px;
  }

  .teaser-3col__item-text {
    font-size: 16px;
  }
}