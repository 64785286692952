.mod-building {
  margin: 50px 0;
  color: $grey-text;

  a {
    color: $grey-text;
  }

  &:last-of-type {
    margin-bottom: 85px;
  }
}

.mod-building .h2 {
  margin-bottom: 10px;
}

.mod-building__data {
  padding-top: 10px;
}

.mod-building__data ul {
  padding: 0;
  list-style: none;
}

.mod-building__data ul li {
  margin-bottom: 10px;
}

.mod-building__data ul li strong {
  display: block;
}

.mod-building__contact {
  padding: 10px 15px;
  background: $tertiary;
  height: 100%;
}

.mod-building__contact-item {
  margin-bottom: 20px;
}

.mod-building__contact .icon-phone {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;

  img {
    max-height: 100%;
  }
}

.mod-building__contact .icon-mail {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 8px;

  img {
    max-height: 100%;
  }
}