.aplist {
  margin: 30px 0 50px 0;
}

.aplist__item {
  width: 100%;
  margin-bottom: 15px;
}

.aplist__item-table {
  width: 100%;
}

.aplist__item-table tr.bigborder {
  border-bottom: 10px solid $tertiary;
  line-height: 2;
}

.aplist__item-table tr {
  padding: 10px 0;
}

.aplist__item-table tr td {

  &:first-of-type {
    font-weight: 700;
    vertical-align: baseline;
  }
}

.aplist__item-table tr td a {
  color: #212529;

  &:hover {
    cursor: pointer;
  }
}

.aplist__item-table .icon-phone {
  white-space: nowrap;
}

.aplist__item-table .icon-phone img {
  height: 17px;
  margin-right: 10px;
}

.aplist__item-table .icon-mail img {
  position: relative;
  top: -2px;
  height: 18px;
  margin-right: 10px;
}

.aplist__item-table .aplist__item-table-inner tr {
  padding: 0;
}

.aplist__item-table .aplist__item-table-inner tr td:first-of-type {
  font-weight: normal;
}



@include media-breakpoint-down(sm) {

  .aplist__item-table,
  .aplist__item-table tr,
  .aplist__item-table tr td {
    display: block;
  }
}

@include media-breakpoint-up(md) {

  .aplist__item-table {
    //table-layout: fixed;
  }

  .aplist__item-table tr td {
    padding: 15px 10px;

    &:first-of-type {
      padding-left: 0;
    }
  }

  .aplist__item-table tr td:nth-of-type(1) {
    width: 175px;
  }

  .aplist__item-table .aplist__item-table-inner tr td {
    padding: 0 10px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(1) {
    width: 165px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(2) {
    width: 110px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(3) {
    width: 190px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(4) {
    width: 30px;
  }

}

@include media-breakpoint-up(xl) {

  .aplist__item-table {
    table-layout: fixed;
  }

  .aplist__item-table tr td {
    padding: 15px 10px;

    &:first-of-type {
      padding-left: 0;
    }
  }

  .aplist__item-table tr td:nth-of-type(1) {
    width: 250px;
  }

  .aplist__item-table .aplist__item-table-inner tr td {
    padding: 0 10px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(1) {
    width: 205px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(2) {
    width: 190px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(3) {
    width: 250px;
  }

  .aplist__item-table .aplist__item-table-inner tr td:nth-of-type(4) {
    width: 220px;
  }

}