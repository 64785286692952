.video-full {
  padding: 40px 0 70px 0;
  background-color: $tertiary;
}

.video-full .embed-responsive {
  margin-top: 10px;
}

.video-full .wrapper {
  position: relative;
}

.video-full .wrapper img {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.video-full .wrapper iframe {
  display: none;
}

.play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
  width: 100px;
  height: 100px;
}

.play-btn:hover {
  cursor: pointer;
}

.play-btn:focus {
  outline: 0;
}