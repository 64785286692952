.hide-robot {
  display: none;
}

.remove_mb p {
  margin-bottom: 0;
}

.captchaField {
  position: absolute;
  top: 4px;
  right: $grid-gutter-width / 2;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
}

.form-control.is-valid {
  border-color: #ced4da;
  background-image: none;
}

.form-group {
  margin-bottom: 0;
}

.form-control.is-invalid {
  background-image: none;
}

@include media-breakpoint-up(sm) {
  .form-group {
    margin-bottom: 1rem;
  }

  .form-inline .form-control {
    width: 380px;
  }
}