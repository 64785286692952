@include media-breakpoint-down(xs) {

  //.nav-outer {
  //  margin-top: -33px;
  //}

  .nav-outer > .container {
    padding-left: 0;
    padding-right: 0;
  }

}

@include media-breakpoint-down(sm) {

  .nav-outer {
    margin-top: -23px;
    //z-index: 1000;
    position: relative;
  }

  .nav-container {
    padding: 0;
  }

  .navbar-toggle {
    background-color: #eee;
    margin: 0 0 0 -15px;
    border: none;
    border-radius: 0;
    width: 55px;
    height: 33px;
    padding: 0 13px;
    float: right;
    .icon-bar {
      background-color: #666;
      height: 2px;
      border-radius: 1px;
      display: block;
    }
    .icon-bar + .icon-bar {
      margin-top: 4px;
    }
    /*&:hover, &:focus {
      background-color: $brand-grey;
    }*/
  }

  .navbar-toggle[aria-expanded="true"] {
    .icon-bar--one {
      transform: rotate(45deg) translate(6px, 2px);
      width: 25px;
    }
    .icon-bar--two {
      opacity: 0;
    }
    .icon-bar--three {
      transform: rotate(-45deg) translate(6px, -3px);
      width: 25px;
    }
  }

  .navbar-toggle[aria-expanded="true"] .icon-bar {
    transition: .20s;
  }

  //.icon-bar {
  //  display: block;
  //  width: 22px;
  //  height: 2px;
  //  border-radius: 1px;
  //}
  //.icon-bar + .icon-bar {
  //  margin-top: 4px;
  //}

  #main-nav {
    border-color: transparent;
    margin-right: 0;
    padding-right: 0;
    border-top: none;
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  #main-nav ul {
    list-style-type: none;
    margin-bottom: 0;
  }

  #main-nav ul {
    background: #f5f6f7;
    position: relative;
    float: none;
    overflow: hidden;
    font-size: 12px;
  }

  #main-nav ul li {
    position: relative;
    border-top: 1px solid #fff;
  }

  #main-nav ul li a {
    color: #000;
    &:hover, &:focus, &:active {
      color: $primary!important;
    }
  }

  .dropdown-toggle {
    border-bottom: none!important;
  }

  #main-nav > ul > li:first-of-type {
    border: none;
  }

  #main-nav ul li ul {
    padding: 0;
  }

  #main-nav > ul > li {
    padding: 0;
  }

  #main-nav > ul > li a {
    padding: 8px 80px 8px 10px;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }

  #main-nav ul.level1 {
    padding: 0;
  }

  #main-nav div.level2 {
    background-color: #ebeced;
    display: none;
  }

  #main-nav ul.level2__ul {
    background-color: #ebeced;
  }

  #main-nav ul.level3 {
    background-color: #e2e3e4;
    display: none;
  }

  #main-nav ul.level4 {
    background-color: #d7d8d9;
    display: none;
  }

  #main-nav div.level2 a {
    padding-left: 20px;
  }

  #main-nav ul.level3 a {
    padding-left: 30px;
  }

  #main-nav ul.level4 a {
    padding-left: 40px;
  }

  .icon-unfold:before {
    font-family: 'FontAwesome';
    content: "\f105";
    color: #fff;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 9px;
    width: 55px;
    height: 37px;
    text-align: center;
    background-color: $primary;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    z-index: 1000;
  }

  .icon-unfold--down:before {
    content: "\f107";
  }

}

@include media-breakpoint-down(md) {

  .level2__container {
    padding: 0;
  }

}

@include media-breakpoint-up(md) {

  .nav-outer.sticky {
    position: sticky;
    z-index: 1;
    top: 0;
  }

  .nav-outer {
    margin: 0;
    background-color: $primary;
    padding: 3px 0 0 0;
  }

  #main-nav {
    display: block;
    margin: 0 auto;
  }

  .navbar-toggle {
    display: none;
  }

  #main-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  //#main-nav > ul {
  //  text-align: center;
  //}

  #main-nav .level1 {
    display: inline-block;
    float: right;
  }

  #main-nav .level1 > li {
    display: inline-block;
  }

  #main-nav .level1 > li.dropdown {
    position: inherit;
  }

  #main-nav .level1 > li:hover {
    //background-color: #fff;
    > a {
      //color: $primary;
    }
  }

  #main-nav .level1 > li > a {
    display: inline-block;
    padding-top: 9px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    width: 150px;
    height: 44px;
    text-align: center;
    margin-bottom: 3px;
    transition: all 0.2s 0.2s;
    &:hover {
      background-color: #fff;
      color: $primary;
    }
    &.active {
      box-shadow: 0 3px 0 0 #fff;
    }
  }

  #main-nav .dropdown:hover > a {
    background-color: #fff;
    color: $primary;
  }

  #main-nav .level2 {
    font-size: 16px;
    position: absolute;
    z-index: 101;
    visibility: hidden;
    background: rgba(255, 255, 255, 1.0);
    opacity: 0;
    transition: all 0.2s 0.2s;
    width: 100%;
    left: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 0px 30px 30px 0px rgba(0,0,0,0.1);
  }

  #main-nav .level2 > li {
    padding: 8px 0;
    line-height: 15px;
  }

  #main-nav .level2__ul > li > a {
    text-align: left;
    //white-space: nowrap;
    color: #555;
    display: inline-block;
    font-size: 18px;
    padding-left: 25px;
    position: relative;
    &:hover {
      color: $primary;
      &:before {
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 14px;
        content: '';
        background-size: contain;
        background-image: url("../img/right-arrow-blue.svg");
        background-repeat: no-repeat;
        left: 5px;
        top: 5px;
      }
    }
  }

  #main-nav .level2__ul > li:not(:last-of-type) > a {
    margin-bottom: 15px;
  }

  #main-nav .dropdown:hover {
    //padding-bottom: 8px;
    > .level2 {
      visibility: visible!important;
      opacity: 1;
    }
  }

  #main-nav .level3 {
    display: none;
  }

  // http://jsfiddle.net/doubleswirve/xbLrW/2/

  .nav-headline {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .dropdown-teaser__title {
    display: block;
    height: 38px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 7px;
    text-align: center;
    top: -38px;
    position: relative;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  //#main-nav .level1 {
  //  float: right;
  //}

  #main-nav .level1 > li > a {
    width: 208px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  #main-nav .level1 > li > a {
    width: 260px;
  }

}
