.imgteaser-4col {
  margin: 30px 0 40px 0;
}

.imgteaser-4col__item {
  display: block;
  margin-bottom: 30px;
}

.imgteaser-4col__item-img {
  display: block;
  position: relative;
  padding-bottom: 70%;
  width: 100%;
  margin-bottom: 15px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.imgteaser-4col__item-imgoverlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #FFF;
  text-align: center;
  background: $primary;
  opacity: 0;
  transition: opacity 0.3s;

  span {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.imgteaser-4col__item:hover .imgteaser-4col__item-imgoverlay,
.imgteaser-4col__item:active .imgteaser-4col__item-imgoverlay,
.imgteaser-4col__item:focus .imgteaser-4col__item-imgoverlay {
  opacity: 0.8;
}

.imgteaser-4col__item-title {
  display: block;
  font-size: 20px;
  color: $primary;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.2;
}

.imgteaser-4col__item-text {
  display: block;
  color: $grey-text;
  font-size: 16px;
  line-height: 1.2;
}