@include media-breakpoint-down(md) {

  .footer-block {
    margin-bottom: 30px;
  }

  .footer-logo {
    max-width: 200px;
  }

}

footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $secondary;
  margin-top: 50px;
}

.footer-title {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
}

.footer-text {
  color: #fff;
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-nav, .footer-text {
  font-family: 'Roboto Condensed';
}

.footer-nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-nav__listitem {
  display: block;
  margin-bottom: 10px;
}

.footer-nav__listitem a {
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
}

.copyright {
  color: #fff;
}

.table--openinghours {
  margin-bottom: 8px;
}

.table--openinghours td {
  padding: 0;
  color: #fff;
}

.table--openinghours tr > td:first-child {
  min-width: 75px;
}

@include media-breakpoint-up(md) {

  footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }

}