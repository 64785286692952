.downloads {
  margin: 25px 0 50px 0;

  .downloads-title {
    margin-bottom: 15px;
  }
  .download-list {
    list-style: none;
    padding: 0;
  }
  .download-item {
    margin-bottom: 5px;
  }
  .download-item a {
    color: #000;
    display: block;
    position: relative;
    padding-left: 30px;
  }
  .download-item svg {
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    margin-right: 10px;
  }
}