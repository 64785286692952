p {
  font-size: 16px;
  color: #555555;
  line-height: 1.5;
}

.text {
  margin: 20px 0 50px;
}

.section__text-cols2 {
  margin-bottom: 20px;
}

.wysiwyg-content ul {
  padding-left: 20px;

  li {
    color: $grey-text;
    list-style-type: disc;
    padding: 0;
    line-height: 1.5;
    margin-bottom: 10px;

    &:before {
      content: none !important;
    }
  }
}

@include media-breakpoint-up(md) {

  .text-cols2 {
    columns: 2;
    //page-break-inside: avoid;
    //break-inside: avoid;
    column-gap: 30px;
  }

}

