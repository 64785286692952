.h1 {
  color: $primary;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.container.h1 {
  margin-top: 25px;
}

.h2 {
  color: $primary;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}

.h3 {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 15px;
}

.container.h1 + .container.h3 {
  margin-top: -5px;
}

.h1 + .h3 {
  margin-top: -5px;
}

@include media-breakpoint-up(md) {

  .h1 {
    font-size: 38px;
  }

  .h2 {
    font-size: 26px;
  }

  .h3 {
    font-size: 20px;
  }
}



/*
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
  margin-top: 0;
  font-weight: normal;
}

h1, .h1 {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 16px;
}

h2, .h2 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
}

h3, .h3 {
  font-size: 18px;
  line-height: 20px;
}


@include media-breakpoint-up(md) {

  h1, .h1 {
    font-size: 42px;
    line-height: 46px;
  }

  h2, .h2 {
    font-size: 30px;
    line-height: 34px;
  }

  h3, .h3 {
    font-size: 26px;
    line-height: 28px;
  }

}


@include media-breakpoint-up(lg) {

  h1,.h1 {
    font-size: 50px;
    line-height: 55px;
  }

  h2, .h2 {
    font-size: 38px;
    line-height: 41px;
    margin-bottom: 20px;
  }

}

*/