body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: Roboto, Helvetica, sans-serif;
  hyphens: auto;
}

p {
  font-size: 16px;
  line-height: 18px;
  hyphens: auto;
}

.custom-row {
  margin-left: -15px;
  margin-right: -15px;
}

.embed-responsive privacy-proxy-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}