.mainslider {
  background: $primary;
}

.mainslider__item-image {
  padding-bottom: 45%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.mainslider__item-overlay {
  display: block;
  background-color: $primary;
  padding: 15px;
}

.mainslider__item-title {
  display: block;
  color: #FFF;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 5px;
}

.mainslider__item-text {
  display: block;
  color: #FFF;
  font-size: 16px;
  line-height: 1.2;
}

.mainslider .slick-dots {
  bottom: 0;
}
.mainslider .slick-dots li {
  height: 30px;
  margin: 0;
}

.mainslider .slick-dots li button {
  position: relative;
  top: 50%;
  margin: -6px auto 0 auto;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
}

.mainslider .slick-dots li.slick-active button {
  background-color: $primary;
  border: 1px solid #FFF;
}

.mainslider .slick-dots li button:before {
  content: none;
}

.mainslider .slick-next, .mainslider .slick-prev {
  height: 62px;
  width: 20px;

  &:before {
    content: none;
  }
}

.mainslider.slick-dotted.slick-slider {
  margin-bottom: 0!important;
}

@include media-breakpoint-down(sm) {

  .mainslider__item > .container {
    padding: 0!important;
  }

  .mainslider.slick-dotted .mainslider__item-overlay {
    padding-bottom: 30px;
  }
}


@include media-breakpoint-up(md) {

  .mainslider__item-overlay {
    position: absolute;
    background-color: #FFF;
    bottom: 30px;
    max-width: 570px;
    z-index: 20;
  }

  .mainslider__item-title {
    color: $primary;
  }

  .mainslider__item-text {
    color: #555555;
  }

  .mainslider .slick-dots li.slick-active button {
    border: none;
  }

  .mainslider .slick-prev {
    left: 50%;
    opacity: 0.5;
    transition: opacity 0.3s;
    z-index: 10;
    cursor: pointer;
    margin-left: -369px;

    &:hover {
      opacity: 1;
    }
  }

  .mainslider .slick-next {
    right: 50%;
    opacity: 0.5;
    transition: opacity 0.3s;
    z-index: 10;
    cursor: pointer;
    margin-right: -369px;

    &:hover {
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(lg) {

  .mainslider__item-overlay {
    padding: 20px 50px 25px 40px;
  }

  .mainslider .slick-prev {
    margin-left: -472px;
  }

  .mainslider .slick-next {
    margin-right: -472px;
  }

}


@include media-breakpoint-up(xl) {

  .mainslider__item-image {
    padding-bottom: 38%;
  }

  .mainslider__item-overlay {
    bottom: 70px;
  }

  .mainslider__item-title {
    font-size: 38px;
  }

  .mainslider__item-text {
    font-size: 20px;
  }

  .mainslider .slick-dots {
    bottom: 25px;
  }

  .mainslider .slick-dots li {
    height: 20px;
  }

  .mainslider .slick-prev {
    margin-left: -575px;
  }

  .mainslider .slick-next {
    margin-right: -575px;
  }

}

@media (min-width: 1320px) {

  .mainslider .slick-prev {
    margin-left: -640px;
  }

  .mainslider .slick-next {
    margin-right: -640px;
  }

}