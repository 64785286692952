.iconteaser-4col {
  background-color: $tertiary;
  box-shadow: 0 1px 5px 0 #cdcdcd;
}

.iconteaser-4col__item {
  display: block;
  padding: 25px 0;
  text-align: center;
}

.iconteaser-4col__item-icon {
  display: block;
  height: 75px;
  margin-bottom: 20px;

  img {
    max-height: 100%;
  }
}

.iconteaser-4col__item-title {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  color: $primary;
  font-weight: 700;
  margin-bottom: 10px;
}

.iconteaser-4col__item-text {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  color: #555555;
  font-weight: 400;
}