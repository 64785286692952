.header {
  padding-top: 10px;
}

.header-inner {
  position: relative;
}

.logo {
  display: inline-block;
  float: left;
  width: 150px;
  position: relative;
  top: 25px;
  transition: top .3s;
  z-index: 1;
}

.global-nav {
  float: right;
  display: inline-block;
  position: relative;
}

.global-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.global-nav__listitem {
  display: inline-block;
  float: left;
  padding-left: 10px;
  line-height: 16px;
}

.global-nav__listitem:not(:last-child) {
  padding-right: 10px;
}

.global-nav__listitem > a > span {
  display: none;
}

.global-nav__listitem-icon {
  width: 20px;
}

.ablesung-hover {
  display: none;
}

.ablesung:hover {
  .ablesung-hover {
    display: block;
    position: absolute;
    top: 40px;
    left: -15px;
    text-align: center;
  }
}

.search {
  font-family: 'Roboto', sans-serif;
  position: relative;
  border-top: 1px solid #fff;
  background-color: #f5f6f7;
  > form {
    width: 100%;
  }
}

.search .search-input {
  border: none;
  height: 33px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin: 2px;
  &::placeholder {
    color: RGBA(85,85,85,.3);
  }
}

.search-btn {
  position: absolute;
  top: 0;
  border: none;
  background-color: $primary;
  padding: 0;
  height: 100%;
  width: 55px;
  right: 0;
}

.search-btn > img {
  width: 15px;
  height: 15px;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

  .global-nav__listitem > a > span {
    color: #000;
    font-size: 12px;
    display: inline-block;
    &:hover {
      color: $primary;
    }
  }

  .global-nav__listitem-icon {
    margin-right: 10px;
  }

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .search {
    position: absolute;
    border-bottom: 1px solid $primary;
    right: 0;
    top: 32px;
    height: 38px;
    width: 350px;
    border-bottom: 1px solid $primary;
    border-top: none;
    background-color: inherit;
  }

  .search .search-input {
    font-size: 16px;
    margin: 0;
    height: 37px;
  }

  .search-btn {
    width: 46px;
    right: 1px;
    background-color: #fff;
  }

  .search-btn > img {
    width: 26px;
    height: 26px;
  }

  .header {
    padding-top: 36px;
    height: 124px;
  }

  .logo {
    width: 200px;
  }

  .global-nav {
    top: -10px;
  }

  .global-nav__listitem-icon {
    width: 30px;
    margin-right: 10px;
  }

  //.global-nav__listitem-icon--notfall {
  //  width: 32px;
  //}

  .global-nav__listitem > a > span {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .logo {
    position: absolute;
    width: 276px;
    top: 0;
  }

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  .search {
    left: 330px;
    top: 10px;
  }

  .global-nav {
    top: 12px;
  }

}