//
// Custom fonts (e.g. for inserting icomoon font)
// --------------------------------------------------

//Custom font

//@font-face {
//  font-family: 'icomoon';
//  src:  url('#{$custom-font-path}/icomoon.eot?tiyca9');
//  src:  url('#{$custom-font-path}/icomoon.eot?tiyca9#iefix') format('embedded-opentype'),
//  url('#{$custom-font-path}/icomoon.ttf?tiyca9') format('truetype'),
//  url('#{$custom-font-path}/icomoon.woff?tiyca9') format('woff'),
//  url('#{$custom-font-path}/icomoon.svg?tiyca9#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}
//
//[class^="icon-"], [class*=" icon-"] {
//  /* use !important to prevent issues with browser extensions that change fonts */
//  font-family: 'icomoon' !important;
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//
//  /* Better Font Rendering =========== */
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('#{$custom-font-path}/roboto-condensed-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
  url('#{$custom-font-path}/roboto-condensed-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$custom-font-path}/roboto-condensed-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$custom-font-path}/roboto-condensed-v17-latin-300.woff') format('woff'), /* Modern Browsers */
  url('#{$custom-font-path}/roboto-condensed-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$custom-font-path}/roboto-condensed-v17-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}