.wysiwyg-content ul,
#wysiwyg-editor ul,
#tinymce ul {
  padding: 0;
  list-style-type: none;
}

.wysiwyg-content ul li,
#wysiwyg-editor ul li,
#tinymce ul li {
  position: relative;
  padding: 0 0 0 16px;
  font-size: 16px;
  line-height: 18px;
  hyphens: auto;
  &:before {
    content: '';
    background-color: #000;
    display: block;
    width: 7px;
    height: 7px;
    margin-left: -15px;
    position: absolute;
    top: 6px;
    //border-radius: 50%;
  }
}

.wysiwyg-content ol,
#wysiwyg-editor ol,
#tinymce ol {
  padding: 0 0 0 16px;
}

.wysiwyg-content ol li,
#wysiwyg-editor ol li,
#tinymce ol li {
  font-size: 16px;
  line-height: 18px;
  hyphens: auto;
  position: relative;
  background-image: none;
}

.wysiwyg-content a,
#wysiwyg-editor a,
#tinymce a {
  color: $primary;
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}