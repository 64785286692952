.page-link.no-cursor {
  cursor: inherit!important;
}

.search-outer {
  margin: 50px auto 0 0;
  max-width: 950px;
}

.search-outer__search {
  height: 50px;
}

.search-outer form {
  margin-top: 30px;
}

.search-outer .form-group {
  width: 65%;
}

.search-outer .btn {
  height: 50px;
  width: 35%;
  padding: .375rem 0.5rem;
}

.search-list__item {
  margin-bottom: 40px;
}

@include media-breakpoint-up(sm) {

  .search-input {
    width: 100%!important;
  }

  .search-outer .form-group {
    width: 74%;
  }

  .search-outer .btn {
    width: 26%;
  }

  .search-outer .btn {
    padding: .375rem 1.6rem;
  }
}

@include media-breakpoint-up(md) {

  .search-outer .form-group {
    width: unset;
  }

  .search-outer .btn {
    width: unset;
  }
}
