#wvll_modal {

  .nav-pills .nav-link {
    background: $tertiary;

    &.active {
      position: relative;
      background: $primary;

    }

    /*
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      right: -20px;
      top: 0;
      -webkit-transform:rotate(360deg);
      border-style: solid;
      border-width: 20px 0 20px 20px;
      border-color: transparent transparent transparent $primary;
    }
    */
  }

  .nav-pills .nav-link:hover {
    color: $primary !important;
  }

  .nav-pills .nav-link.active:hover {
    color: #fff !important;
  }

  .close {
    width: 35px;
    height: 35px;
    background: $primary;
    color: #fff;
    opacity: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    padding: 0;
    font-weight: 400;
    font-family: "Times New Roman", sans-serif;
    font-size: 32px;
    line-height: 35px;
    z-index: 100;
  }

  .p-highlight {
    color: $primary;
    font-weight: 700;
    font-size: 18px;
  }

  label.p-highlight {
    margin-bottom: 0;
  }

  .modal-header {
    padding: 0;
  }

  #info button.btn, #submission button.btn, #complete button.btn {
    width: 100%;
  }

  .alert-primary {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  @include media-breakpoint-down(sm) {

    table, thead, tbody, th, td, tr {
      display: block;
    }

    tr th {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid #eee;
      padding: 10px 0;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    td:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 45%;
      padding: 5px 10px 5px 0;
      white-space: nowrap;
      font-weight: 700;
    }

    .datenerfassung {
      td:nth-of-type(1):before {
        content: "Lfd.-Nr.";
        line-height: 24px;
      }
      td:nth-of-type(2):before {
        content: "Zählernummer";
        line-height: 24px;
      }
      td:nth-of-type(3):before {
        content: "Anfangsstand";
        line-height: 24px;
      }
      td:nth-of-type(4):before {
        content: "Zählerstand";
        line-height: 38px;
      }
    }

    .datenzusammenfassung {
      td:nth-of-type(1):before {
        content: "Lfd.-Nr.";
        line-height: 24px;
      }
      td:nth-of-type(2):before {
        content: "Zählernummer";
        line-height: 24px;
      }
      td:nth-of-type(3):before {
        content: "Anfangsstand";
        line-height: 24px;
      }
      td:nth-of-type(4):before {
        content: "Zählerstand";
        line-height: 24px;
      }
      td:nth-of-type(5):before {
        content: "Differenz";
        line-height: 24px;
      }
    }

    .persoenlichedaten {
      tr:nth-of-type(1) td:before {
        content: "Tag der Ablesung";
        line-height: 24px;
      }
      tr:nth-of-type(2) td:before {
        content: "Rufnummer";
        line-height: 24px;
      }
      tr:nth-of-type(3) td:before {
        content: "E-Mail:";
        line-height: 24px;
      }
      tr:nth-of-type(4) td:before {
        content: "Bemerkungen";
        line-height: 24px;
      }
    }

  }

  @include media-breakpoint-up(sm) {

    #submission button.btn, #complete button.btn {
      width: 250px;
      float: right;
    }
  }


  @include media-breakpoint-up(md) {

    .modal-content {
      padding: 40px;
    }

    .modal-dialog {
      max-width: 690px;
    }

    .modal-header {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }

    .modal-body {
      padding-left: 0;
      padding-right: 0;
    }

    .close {
      top: 0;
      right: 0;
    }
  }

  @include media-breakpoint-up(lg) {

    .nav-fill .nav-item {
      flex: 1 1;
      padding: 0 5px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .modal-dialog {
      max-width: 930px;
    }

    .modal-header {
      border-bottom: 2px solid $primary;
      margin-bottom: 20px;
    }

    #info button.btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 80%;
    }
  }

  @include media-breakpoint-up(xl) {

    .modal-dialog {
      max-width: 1140px;
    }

  }
}