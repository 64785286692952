.main-teaser__item {
  height: 250px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(md) {

  .main-teaser__item {
    height: 300px;
  }

}

@include media-breakpoint-up(lg) {

  .main-teaser__item {
    height: 450px;
  }

}