.imgtext-2col {
  margin: 50px 0;
}

.imgtext-2col__content {
  margin-top: 15px;

  @include media-breakpoint-down(sm) {
    img { margin-bottom: 15px; }
  }
}

.imgtext-2col__link {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: $primary;
  line-height: 1.2;
  transition: all 0.3s;

  &:before {
    content: "";
    height: 14px;
    width: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
    background-size: contain;
    background-image: url("../img/right-arrow-blue.svg");
    background-repeat: no-repeat;
    margin-right: 7px;
  }
}

